.slide-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  input[type="range"] {
    -webkit-appearance: none;
    width: 100%;
    height: 10px;
    background: #e4e4e4;
    outline: none;
    margin: 10px 0;
    border-radius: 5px;
  }
  
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #3498db;
    cursor: pointer;
    transition: background 0.15s ease-in-out;
  }
  
  input[type="range"]::-webkit-slider-thumb:hover {
    background: #2980b9;
  }
  
  .labels {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
  }
  
  .labels span {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    color: #999;
    cursor: pointer;
  }
  
  .labels .active {
    color: #3498db;
  }
  