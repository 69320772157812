/* .audio-recorder {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem;
  }
  
  .audio-recorder-button {
    background-color: gray;
    border: none;
    border-radius: 50%;
    height: 100px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  .audio-recorder-button:hover {
    background-color: lightgray;
  }
  
  .audio-recorder-button.recording {
    background-color: red;
  }
  
  .audio-recorder-button p {
    margin: 0;
    font-size: 1rem;
    font-weight: bold;
    color: white;
  }
  
  .audio-recorder-audio {
    margin-top: 1rem;
    width: 100%;
    max-width: 300px;
  }
   */

   .audio-recorder-button {
    display: inline-block;
    width: 50px;
    height: 50px;
    background-color: #ccc;
    border-radius: 50%;
    border: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    transition: background-color 0.2s;
  }
  
  .audio-recorder-button.recording {
    background-color: red;
  }
  