.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  /* align-items: flex-start; */
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.chat-log-container-wrapper {
  /* min-width: 100%; */
  padding: 10px;
}

.chat-log-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 100%;
}

.chat-logs {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  background-color: #f6f6f6;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  overflow-y: scroll;
  min-height: 500px;
  max-height: 500px;
  overflow-y: scroll; /* enable vertical scrolling */

}

.chat-message {
  max-width: 70%;
  padding: 4px 8px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  font-size: 0.8em;
  line-height: 1.5;
}

.user-message {
  align-self: flex-end;
  background-color: #dbf5ff;
}

.bot-message {
  align-self: flex-start;
}

.loading-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3px;
}

.loading-dot-1 {
  animation: pulse-1 1s ease-in-out infinite; color: navy;
}
.loading-dot-2 {
  animation: pulse-2 1s ease-in-out infinite; color: maroon;
}
.loading-dot-3 {
  animation: pulse-1 1s ease-in-out infinite; color: navy;
}

@keyframes pulse-1 {
  0% {
    transform: scale(0.7);
  }
  50% {
    transform: scale(2.0);
  }
  100% {
    transform: scale(0.7);
  }
}

@keyframes pulse-2 {
  0% {
    transform: scale(2.0);
  }
  50% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(2.0);
  }
}

.chat-input {
  display: flex;
  font-size: 16px;
  margin: 10px;
  justify-content: space-between;
  display: flex;
}

.chat-input input[type="text"] {
  min-width: 85vw;
  flex: 1 1 auto;
}

.chat-greeting {
  padding-bottom: 20px;
  font-size: 3pc;
}

.input-container {
  display: flex;
  gap: 8px;
  margin-top: 16px;
  padding: 15px;
}

input[type="text"] {
  flex-grow: 1;
  padding: 8px;
  border-radius: 8px;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  font-size: 16px;
}

.send-button {
  padding: 8px 16px;
  border-radius: 8px;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background-color: #2196f3;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
}

.send-button.disabled {
  background-color: #AAAAAA;
}


.options-container {
  display: flex;
  flex-direction: column;
  padding: 15px;
}