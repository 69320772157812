.dropdown-container {
    display: flex;
    align-items: center;
    font-family: Arial, sans-serif;
    justify-content: center;
  }
  
  select {
    padding: 8px 40px 8px 16px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    background-color: #f2f2f2;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    margin-right: 16px;
  }
  
  select:focus {
    outline: none;
    box-shadow: 0px 0px 3px 2px #1e90ff;
  }
  
  option {
    padding: 8px;
  }
  
  img {
    width: 24px;
    height: 16px;
  }
  